import React,{useEffect, useState} from 'react';
import Example from './charts/SemiCircle';
import SemiCircleProgressBar from "react-progressbar-semicircle";

const Scan = () => {
  const [bpscore, setBpScore]=useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('This will run every second!');
        
        getVal()
    }, 1000);
    return () => clearInterval(interval);
      }, []);

  const getVal=()=>{
    var bscore= document.getElementById("diag_score").value

    
    if(bpscore!=bscore){
      setBpScore(bscore)
    }
 }
    return (<>
    {/* <div className='text-2xl font-bold text-center py-3 border-4'>Docsun Real Time Detection</div> */}

      <input id='diag_score' className='hidden' />
    <div className='flex md:flex-row flex-col w-full'>
      <div className='flex bg-black'>
        {/* Video */}
        
        {/* <video hidden id="webcam" width="640" height="480" autoPlay></video>
        <canvas id="canvas" width="640" height="480"></canvas> */}
        {/* <video hidden id="webcam"  width="640" className='w-full' height="480" autoPlay></video>
        <canvas id="canvas"  width="640" className='w-full' height="480"></canvas> */}
        <video hidden id="webcam" width="640" height="480" autoPlay></video>
      <canvas id="canvas" width="640" height="480"></canvas>
      </div>
      <div className='flex flex-col flex-auto bg-black'>
      {/* https://codesandbox.io/s/half-donut-chart-1gj7f?fontsize=14&hidenavigation=1&theme=dark&file=/src/HalfDonutChart.js */}

        {/* Start of one row */}

        <div className='flex flex-row border-b'>
            <div className='w-2/3 border-r flex flex-row flex-wrap'>
              {/* Chart */}
              {/* <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas id="hr_canvas" width="320" height="50"></canvas> */}
              {/* <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas id="hr_canvas" width="320" height="50"></canvas> */}
              {/* <img hidden id="br_img" src="./vitalbox.png" alt="" /> <canvas id="br_canvas" width="320" height="50"></canvas> */}
              <img hidden id="sr_img" src="./vitalbox.png" alt="" /> <canvas class="sr_canvas" width="320" height="130">

              </canvas>
    <img hidden id="dr_img" src="./vitalbox.png" alt="" /> 
    <canvas class="dr_canvas" width="320" height="130"></canvas>
            </div>
            <div className='w-2/3'>
              {/* value */}
              <div className=' text-yellow-500 flex flex-col'>

                <div className='flex flex-row text-3xl font-black'>
                  Blood Pressure

                </div>
                <div className='flex flex-row flex-wrap'>
                  <div className='flex flex-row mt-4'>
                    {/* reading */}
                    <div>

                    <input type="text" id="syst_rate" className='bg-black w-16 text-3xl font-black'  /> 
                    
                    </div>
                    <div className=' font-black text-3xl'>
                      /

                    </div>
                    <div>
                    
                    <input type="text" id="diast_rate" className='bg-black w-16 text-3xl font-black' />

                    </div>
                    
                    
                  </div>
                  <div>
                    {/* chart */}
                    <SemiCircleProgressBar percentage={bpscore} strokeWidth={35} stroke="yellow" diameter={150} />

                  </div>

                </div>
                <div>
                  Range of Detection: (90/20 mmHg  to 180/120  mmHg) <br /> Unit of Detection: mmHg
                </div>
                <div className='text-2xl text-blue-600 font-black mt-4'>
                <div> Diagnosis :  <input type="text" id="diagnosis" className='bg-black' readOnly /><input type="text" hidden id="diag_value" /></div>
                </div>

                
                </div>
            </div>


        </div>  {/* End of one row */}
        <div className=' flex flex-col text-white'>

          <div>
          <h4><b>Instructions to Follow For Accurate Scanning :</b></h4>
        
        <ol className='list-disc ml-4'>
          <li>Please load the page and enable the internal or external camera to scan</li>
          <li>Position the camera accordingly in front of you and perform the scan in a well-lit environment</li>
          <li>Do not do the scan under much backlight</li>
          <li>Adjust your face position to the camera until the green frame is steadily shown and please stay still</li>
          <li>Remove facial accessories such as masks, eyeglasses, and contact lenses for better accurate detection</li>
          <li>The scan lasts untill the health status shows up on the screen. You can click "Stop Scanning" to see your stable results</li>
        </ol>

          </div>
        
          
        </div>


        
        
        

        
        
        
      </div>
      

    </div>

    
    <div className='flex flex-col my-4'>
          {/* Video Stream */}
          <div className='my-3 flex md:flex-row flex-col space-x-4'>
            <div className='space-x-4'>
            


            <button hidden id="scan" className='btn'>Start Idle Scanning</button>
                    <button id="real_scan" className='btn btn-info'>Start Vital Scanning</button>
                    <button id="stop"  className='btn btn-success'>Stop Vital Scanning</button>
              
            </div>
            {/* <div className='text-2xl text-blue-600 font-black'>
            <div> Diagnosis :  <input type="text" id="diagnosis" readOnly /><input type="text" hidden id="diag_value" /></div>
            </div> */}
            
            </div>
          <div className=''>
              {/* <video hidden id="webcam" width="640" height="480" autoPlay></video>
              <canvas id="canvas" width="640" height="480"></canvas> */}
              
            </div>
            
        </div>
  

  <div className='hidden'>
  <img hidden id="sr_img" src="./vitalbox.png" alt="" /> <canvas class="sr_canvas" width="320" height="130"></canvas>
    <img hidden id="dr_img" src="./vitalbox.png" alt="" /> <canvas class="dr_canvas" width="320" height="130"></canvas>
  </div>
  
  
 
  <br />

  
  
    
    
    </> );
}
 
export default Scan;